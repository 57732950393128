var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "content-header" },
        [
          _c(
            "b-col",
            {
              staticClass: "content-header-left mb-2",
              attrs: { cols: "12", md: "9" }
            },
            [
              _c(
                "b-row",
                { staticClass: "breadcrumbs-top" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h2",
                      {
                        staticClass: "content-header-title float-left pr-1 mb-0"
                      },
                      [_vm._v(" Reports ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "breadcrumb-wrapper" },
                      [
                        _c(
                          "b-breadcrumb",
                          [
                            _c(
                              "b-breadcrumb-item",
                              { attrs: { to: "/apps/reports/type" } },
                              [
                                _c("feather-icon", {
                                  staticClass: "align-text-top",
                                  attrs: { icon: "HomeIcon", size: "16" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-breadcrumb-item",
                              { on: { click: _vm.goBack } },
                              [_vm._v(" Reports ")]
                            ),
                            _c("b-breadcrumb-item", { attrs: { active: "" } }, [
                              _vm._v(" Vehicle Log ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.reportData &&
      _vm.clientOptions &&
      (_vm.$can("read", "reports") || _vm.$can("update", "reports"))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Report Details ")])]),
              _c(
                "b-card-body",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.validateForm)
                                    }
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.report_type,
                                        expression: "reportData.report_type"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "report_type"
                                    },
                                    domProps: {
                                      value: _vm.reportData.report_type
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "report_type",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.time_zone,
                                        expression: "reportData.time_zone"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "time_zone"
                                    },
                                    domProps: {
                                      value: _vm.reportData.time_zone
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "time_zone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.reportData.client_display_name,
                                        expression:
                                          "reportData.client_display_name"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "client_display_name"
                                    },
                                    domProps: {
                                      value: _vm.reportData.client_display_name
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "client_display_name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.attachment_list,
                                        expression: "reportData.attachment_list"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "attachment_list"
                                    },
                                    domProps: {
                                      value: _vm.reportData.attachment_list
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "attachment_list",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm.reportData.report_id
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Report ID",
                                                    "label-for": "report_id"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.reportData.report_id
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Report Date",
                                                    "label-for":
                                                      "report_date_local"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.reportData
                                                          .report_date_local
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportData.report_id
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Submitted By",
                                                    "label-for":
                                                      "user_display_name"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.reportData
                                                          .user_display_name
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Client",
                                                "label-for": "clientname"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "clientname",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.clientOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "clientname"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectClient
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .clientname,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "clientname",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.clientname"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site",
                                                "label-for": "sitename"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "sitename",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.siteOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "sitename"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectSite
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .sitename,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "sitename",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.sitename"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site Address",
                                                "label-for": "site_address"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "site_address",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "site_address"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .site_address,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "site_address",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.site_address"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Date:",
                                                "label-for": "inspection_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "inspection_date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id:
                                                                "inspection_date",
                                                              config: {
                                                                enableTime: true,
                                                                dateFormat:
                                                                  "Y-m-d H:i:ss"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .inspection_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "inspection_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.inspection_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Licence Plate:",
                                                "label-for": "licence_plate"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "licence_plate",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "licence_plate"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .licence_plate,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "licence_plate",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.licence_plate"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Vehicle Type",
                                                "label-for": "vehicle_type"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "vehicle_type",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.vehicleTypeOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "vehicle_type"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleVehicleTypeSelection
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .vehicle_type,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "vehicle_type",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.vehicle_type"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Vehicle Make Model:",
                                                "label-for": "make_model"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "make_model",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "make_model"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .make_model,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "make_model",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.make_model"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Vehicle Year:",
                                                "label-for": "vehicle_year"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "vehicle_year",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "vehicle_year"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .vehicle_year,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "vehicle_year",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.vehicle_year"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _vm.reportData.vehicle_type === "Gas"
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Purchased Gas:",
                                                    "label-for":
                                                      "is_purchase_gas"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "is_purchase_gas",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  options:
                                                                    _vm.yesNoOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  "input-id":
                                                                    "is_tow_called"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .reportData
                                                                      .is_purchase_gas,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.reportData,
                                                                      "is_purchase_gas",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "reportData.is_purchase_gas"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportData.is_purchase_gas
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Purchase Gas Price$:",
                                                    "label-for": "gas_price"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "gas_price"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "gas_price",
                                                                    type:
                                                                      "number",
                                                                    step:
                                                                      "0.01",
                                                                    min: "0"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .gas_price,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.reportData,
                                                                        "gas_price",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.gas_price"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Purchase Gas Litres:",
                                                    "label-for": "gas_litres"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "gas_litres"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "gas_litres",
                                                                    type:
                                                                      "number",
                                                                    step: "1",
                                                                    min: "0"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .gas_litres,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.reportData,
                                                                        "gas_litres",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.gas_litres"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportData.vehicle_type === "EV"
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Purchased Electricity",
                                                    "label-for":
                                                      "is_purchase_electricity"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "is_purchase_electricity",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  options:
                                                                    _vm.yesNoOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  "input-id":
                                                                    "is_purchase_electricity"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .reportData
                                                                      .is_purchase_electricity,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.reportData,
                                                                      "is_purchase_electricity",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "reportData.is_purchase_electricity"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportData.is_purchase_electricity
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Charge Fee:",
                                                    "label-for": "charge_fee"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "charge_fee"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "charge_fee",
                                                                    type:
                                                                      "number",
                                                                    step:
                                                                      "0.01",
                                                                    min: "0"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .charge_fee,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.reportData,
                                                                        "charge_fee",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.charge_fee"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Charge Percent:",
                                                    "label-for":
                                                      "charge_percent"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "charge_percent"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "charge_percent",
                                                                    type:
                                                                      "number",
                                                                    step: "1",
                                                                    min: "0",
                                                                    max: "100"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .charge_percent,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.reportData,
                                                                        "charge_percent",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.charge_percent"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportData.vehicle_type === "Gas"
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Last Oil change:",
                                                    "label-for":
                                                      "last_oil_change"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "last_oil_change",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "last_oil_change"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .last_oil_change,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.reportData,
                                                                        "last_oil_change",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.last_oil_change"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("b-col", { attrs: { md: "6" } })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportData.vehicle_type === "Gas"
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Next Oil Change:",
                                                    "label-for":
                                                      "next_oil_change"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "next_oil_change",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "next_oil_change"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .next_oil_change,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.reportData,
                                                                        "next_oil_change",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.next_oil_change"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("b-col", { attrs: { md: "6" } })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Starting KM:",
                                                "label-for": "starting_km"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "starting_km",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "starting_km"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .starting_km,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "starting_km",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.starting_km"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Ending KM:",
                                                "label-for": "ending_km"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "ending_km",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "ending_km"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .ending_km,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "ending_km",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.ending_km"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Leaks:",
                                                "label-for": "leaks"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "leaks" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "leaks"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .leaks,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "leaks",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.leaks"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Dents/Scratches:",
                                                "label-for": "dents_scratches"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "dents_scratches"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              attrs: {
                                                                id:
                                                                  "dents_scratches",
                                                                rows: "3",
                                                                "max-rows": "8"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .dents_scratches,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "dents_scratches",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.dents_scratches"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Tire Pressure/Tred:",
                                                "label-for": "tire_pressure"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "tire_pressure"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "tire_pressure"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .tire_pressure,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "tire_pressure",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.tire_pressure"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Decals/Lights:",
                                                "label-for": "decals_lights"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "decals_lights"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "decals_lights"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .decals_lights,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "decals_lights",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.decals_lights"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Upholstery (Floor, Seats, Doors, Dashboard, Roof):",
                                                "label-for": "upholstery"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "upholstery" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              attrs: {
                                                                id:
                                                                  "upholstery",
                                                                rows: "3",
                                                                "max-rows": "8"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .upholstery,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "upholstery",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.upholstery"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Seat belts (Working):",
                                                "label-for": "seatbelts"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "seatbelts" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "seatbelts"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .seatbelts,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "seatbelts",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.seatbelts"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Folding Seats (Working):",
                                                "label-for": "folding_seats"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "folding_seats"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "folding_seats"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .folding_seats,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "folding_seats",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.folding_seats"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Headrests (Working):",
                                                "label-for": "headrests"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "headrests" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "headrests"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .headrests,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "headrests",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.headrests"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Glowlights (Dashboard):",
                                                "label-for": "glow_lights"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "glow_lights" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "glow_lights"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .glow_lights,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "glow_lights",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.glow_lights"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Interior Lights:",
                                                "label-for": "interior_lights"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "interior_lights"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "interior_lights"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .interior_lights,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "interior_lights",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.interior_lights"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Radio / Volume (All Outputs):",
                                                "label-for": "radio"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "radio" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "radio"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .radio,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "radio",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.radio"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Window (Driver controls / Passenger controls):",
                                                "label-for": "windows"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "windows" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "windows"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .windows,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "windows",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.windows"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Mirrors:",
                                                "label-for": "mirrors"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "mirrors" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "mirrors"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .mirrors,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "mirrors",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.mirrors"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Air Controls:",
                                                "label-for": "air_controls"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "air_controls" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "air_controls"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .air_controls,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "air_controls",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.air_controls"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Wipers:",
                                                "label-for": "wipers"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "wipers" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "wipers"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .wipers,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "wipers",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.wipers"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Lights / High-beams / Signals / Brakes:",
                                                "label-for": "signal_lights"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "signal_lights"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "signal_lights"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .signal_lights,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "signal_lights",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.signal_lights"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Electric Seats (All Positions):",
                                                "label-for": "electric_seats"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "electric_seats"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "electric_seats"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .electric_seats,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "electric_seats",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.electric_seats"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Locks (Driver / Individual):",
                                                "label-for": "door_locks"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "door_locks" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "door_locks"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .door_locks,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "door_locks",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.door_locks"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Horn:",
                                                "label-for": "horn"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "horn" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "horn"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .horn,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "horn",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.horn"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Fluids (Windshield, Power steering, Oil, Brake fluid):",
                                                "label-for": "fluids"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "fluids" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "fluids"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .fluids,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "fluids",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.fluids"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Belts:",
                                                "label-for": "belts"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "belts" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "belts"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .belts,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "belts",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.belts"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Filters",
                                                "label-for": "filters"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "filters" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "filters"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .filters,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "filters",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.filters"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Battery Terminals:",
                                                "label-for": "battery"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "battery" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "battery"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .battery,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "battery",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.battery"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Notes:",
                                                "label-for": "notes"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "notes" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              attrs: {
                                                                id: "notes",
                                                                rows: "3",
                                                                "max-rows": "8"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .notes,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "notes",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.notes"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.reportID === null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can("create", "reports")
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick,
                                                            type: "submit"
                                                          }
                                                        },
                                                        [_vm._v(" Add Report ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportID !== null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can("update", "reports")
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick,
                                                            type: "submit"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Save Changes "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.$can("read", "reports")
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant:
                                                              "secondary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.printReport
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Print Report "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.$can("delete", "reports")
                                            ? _c(
                                                "b-row",
                                                { staticClass: "mt-4" },
                                                [
                                                  !_vm.reportData.approved
                                                    ? _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Approve "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "secondary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Unapprove "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3933286172
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.reportData &&
      _vm.reportID !== null &&
      (_vm.$can("read", "reports") || _vm.$can("update", "reports"))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Photos / Images ")])]),
              _c("b-card-body", [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.reportData &&
                        _vm.clientOptions &&
                        _vm.$can("update", _vm.abilityRequired) &&
                        _vm.reportData.username === _vm.userData.username
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openCamera }
                                  },
                                  [_vm._v(" Take Picture ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.reportData &&
                        _vm.clientOptions &&
                        _vm.$can("update", _vm.abilityRequired) &&
                        _vm.reportData.username === _vm.userData.username
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openImageSelector }
                                  },
                                  [_vm._v(" Add Image ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.reportData.attachment_list
                  ? _c(
                      "section",
                      { staticClass: "grid-view" },
                      _vm._l(_vm.reportData.attachment_list, function(
                        mediaItem
                      ) {
                        return _c(
                          "b-card",
                          {
                            key: mediaItem.filename,
                            ref: mediaItem.filename,
                            refInFor: true,
                            attrs: { id: mediaItem.filename, "no-body": "" }
                          },
                          [
                            mediaItem.filename.slice(-3) === "jpg"
                              ? _c(
                                  "div",
                                  { staticClass: "item-img text-center" },
                                  [
                                    _c("thumbs-lazy-image-component", {
                                      staticClass: "card-img-top",
                                      attrs: {
                                        src: mediaItem.access_url,
                                        "src-placeholder":
                                          "@/assets/images/noImage.png"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            mediaItem.filename.slice(-3) === "jpg" &&
                            _vm.$can("update", _vm.abilityRequired) &&
                            _vm.reportData.username === _vm.userData.username
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "pt-1 pb-1 border-dark" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                attrs: {
                                                  variant: "primary",
                                                  block: "",
                                                  disabled:
                                                    _vm.preventDoubleClick
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteImage(
                                                      mediaItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Delete Image ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  ref: "cameraInput",
                  staticStyle: { display: "none" },
                  attrs: {
                    type: "file",
                    accept: "image/*",
                    capture: "environment"
                  },
                  on: { change: _vm.handleImageChange }
                }),
                _c("input", {
                  ref: "imageInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "image/*" },
                  on: { change: _vm.handleImageChange }
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.reportData &&
      _vm.reportID !== null &&
      (_vm.$can("read", "reports") || _vm.$can("update", "reports"))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Files ")])]),
              _c("b-card-body", [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.reportData &&
                        _vm.clientOptions &&
                        _vm.$can("update", _vm.abilityRequired) &&
                        _vm.reportData.username === _vm.userData.username
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openFileSelector }
                                  },
                                  [_vm._v(" Add File ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.reportData.attachment_list
                  ? _c(
                      "section",
                      { staticClass: "grid-view" },
                      _vm._l(_vm.reportData.attachment_list, function(
                        mediaItem
                      ) {
                        return _c(
                          "b-card",
                          {
                            key: mediaItem.filename,
                            ref: mediaItem.filename,
                            refInFor: true,
                            attrs: { id: mediaItem.filename, "no-body": "" }
                          },
                          [
                            mediaItem.filename.slice(-3) !== "jpg"
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "pt-1 pb-1 border-dark" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                attrs: {
                                                  variant: "primary",
                                                  block: "",
                                                  disabled:
                                                    _vm.preventDoubleClick
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.downloadFile(
                                                      mediaItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Download File ")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.reportData &&
                                        _vm.clientOptions &&
                                        _vm.$can(
                                          "update",
                                          _vm.abilityRequired
                                        ) &&
                                        _vm.reportData.username ===
                                          _vm.userData.username
                                          ? _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                    attrs: {
                                                      variant: "primary",
                                                      block: "",
                                                      disabled:
                                                        _vm.preventDoubleClick
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteFile(
                                                          mediaItem
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" Delete File ")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  ref: "fileInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "*/*" },
                  on: { change: _vm.handleFileChange }
                })
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }